import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-reload"
export default class extends Controller {
  static targets = ['frame']

  perform() {
    this.frameTargets.forEach(frame => frame.reload())
  }
}
