import { Controller } from "@hotwired/stimulus"
import { languageId } from "../../editor/constants"
import * as monaco from "monaco-editor"

export default class extends Controller {
  static targets = ['editor', 'input']

  static values = {ready: Boolean}

  #editor

  connect() {
    this.#editor = monaco.editor.create(this.editorTarget, this.#editorOptions)

    this.#editor.onDidChangeModelContent(() => {
      this.inputTarget.value = this.#editor.getValue()
      this.dispatch('model-changed')
    })

    this.#editor.onDidBlurEditorText(() => {
      this.dispatch('blur')
    })

    this.element.monacoEditor = this.#editor
    this.readyValue = true
  }

  disconnect() {
    this.#editor?.dispose()
  }

  get #editorOptions() {
    return {
      automaticLayout: true,
      contextmenu: false,
      extraEditorClassName: 'formula-editor',
      fontSize: 14,
      glyphMargin: false,
      language: languageId,
      lineNumbers: 'off',
      minimap: { enabled: false },
      padding: { top: 10, bottom: 10 },
      renderLineHighlight: 'none',
      scrollbar: { verticalScrollbarSize: 4, horizontalScrollbarSize: 4, alwaysConsumeMouseWheel: false },
      tabSize: 2,
      lineDecorationsWidth: 0,
      wordWrap: 'on',
      scrollBeyondLastLine: false,
      value: this.inputTarget.value,
    }
  }
}
