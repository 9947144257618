import { language, config, theme } from "../editor/config"
import { languageId, themeId } from "../editor/constants"
import * as monaco from "monaco-editor"

monaco.editor.defineTheme(themeId, theme)
monaco.editor.setTheme(themeId)

monaco.languages.register({ id: languageId })
monaco.languages.setMonarchTokensProvider(languageId, language)
monaco.languages.setLanguageConfiguration(languageId, config)
