import { Controller } from "@hotwired/stimulus"
import * as monaco from "monaco-editor"

// this code is functional, but not yet connected to the editor
export default class extends Controller {
  #editor

  connect() {
    this.#editor = this.element.monacoEditor
  }

  perform({params: {variables = []}}) {
    variables.forEach(variable => {
      this.#textModel.findMatches(variable).forEach(({range}) => {
        monaco.editor.setModelMarkers(
          this.#textModel,
          this.#editor,
          [{severity: monaco.MarkerSeverity.Error, message: 'Unknown Variable', ...range}]
        )
      })
    })
  }

  clear() {
    monaco.editor.removeAllMarkers(this.#editor)
  }


  get #textModel() {
    return this.#editor.getModel()
  }
}
