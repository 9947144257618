import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = { url: String, count: Number }

  async perform({ params: { url: urlParam } }) {
    const query = new FormData(this.element)

    const response = await get(urlParam || this.urlValue, { query, responseKind: 'turbo-stream' })
    if (response.ok) this.countValue += 1
  }
}
