import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]
  static classes = ["hidden"]

  perform() {
    this.element.classList.add(this.hiddenClass)
    this.inputTarget.value = "1"
  }
}
