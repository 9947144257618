import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggle", "field", "fieldContainer" ]

  connect() {
    this.perform({currentTarget: this.toggleTarget})
  }

  perform({currentTarget: { checked }}) {
    this.fieldTargets.forEach(field => field.toggleAttribute('disabled', !checked))
    if (this.hasFieldContainerTarget) this.fieldContainerTarget.classList.toggle('display-none', !checked)
  }
}
