import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']
  static classes = ['active']

  select({currentTarget}) {
    this.itemTargets.forEach(item => item.classList.remove(this.activeClass))
    currentTarget.classList.add(this.activeClass)
  }
}
