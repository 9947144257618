import { Controller } from "@hotwired/stimulus"
import { languageId } from "../../editor/constants"
import * as monaco from "monaco-editor"

export default class extends Controller {
  static values = { variables: Array, values: Array, keywords: Array }

  #completionProviders = []

  connect() {
    this.#registerCompletionProvider(
      this.variablesValue,
      monaco.languages.CompletionItemKind.Variable
    )
    this.#registerCompletionProvider(
      this.valuesValue,
      monaco.languages.CompletionItemKind.Value
    )
    this.#registerCompletionProvider(
      this.keywordsValue,
      monaco.languages.CompletionItemKind.Keyword
    )
  }

  disconnect() {
    this.#completionProviders.forEach(provider => provider.dispose())
  }

  #registerCompletionProvider(completions, completionType) {
    const provider = monaco.languages.registerCompletionItemProvider(languageId, {
      provideCompletionItems: () => {
        const suggestions = [
          ...completions.map(suggestion => ({
            label: suggestion,
            kind: completionType,
            insertText: suggestion,
          })),
        ]
        return { suggestions }
      }
    })

    this.#completionProviders.push(provider)
  }
}
